// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostCategoryNavbar from "../navbar/BlogPostCategoryNavbar.res.js";
import * as BlogPostCategoryEditForm from "./BlogPostCategoryEditForm.res.js";
import * as BlogPostCategoryEditScss from "./BlogPostCategoryEdit.scss";

var css = BlogPostCategoryEditScss;

function BlogPostCategoryEdit$default(props) {
  var blogPostCategory = props.blogPostCategory;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(BlogPostCategoryNavbar.make, {
                      selectedCategory: "EditBlogPostCategory",
                      id: Caml_option.some(blogPostCategory.id)
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "Edit Blog Post Category"
                            }),
                        JsxRuntime.jsx(BlogPostCategoryEditForm.make, {
                              blogPostCategory: blogPostCategory
                            })
                      ]
                    })
              ],
              className: css.pageContainer
            });
}

var $$default = BlogPostCategoryEdit$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
